<template>
    <SmartTable url="/document/tag/index" base_path="/document/tag"></SmartTable>
</template>
<script>
import SmartTable from "@/view/components/SmartTable.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components: {
        SmartTable
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Documenti", route: "/document" },
            { title: "Tag", route: "/document/tag/index" },
            { title: "Ricerca Tag" }
        ]);
    },
};
</script>
